import __NS_DYNAMIC_REACT_CONTENT___0 from "./biometrics-security-path-content.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
export const BiometricsSecurityPathDashboard = ({
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  return dashboardMfeEnabled ? createElement(Fragment, null, loginPreferencesMfeEnabled ? createElement(Content, {
    hash: "81bbd72f0c4dd5093d9fa696943fcd22",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "30f9cd434ba8d3fae028f500524c4309",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })) : createElement(Content, {
    hash: "c5adbaa86435d40404265c9b4efef9ec",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};