export default { 
  h17c492e880243569be946c6acabd1b19: [[({}, []) => ["Face ID is a registered trademark of Apple. Inc."], {}]],
  h1e7aa99346d47efa0054c9f7d4bf03c4: [[({}, []) => ["Cancel"], {}]],
  h544dc8009a8dd09798f6db74dbd7365b: [[({
  brand: brand
}, []) => ["Your ", brand.name, " Account is a Fingerprint Away"], {}]],
  h5af2eb5597dc23510bc29a1842032d40: [[({
  brand: brand
}, []) => ["You now have the option of using Face ID\xAE to log in to the", " ", brand.name, " mobile app! Once you enable this option, login will be much easier and faster!"], {}]],
  h7fe0b1bf14bff27906bc63dd9fe1c7a9: [[({
  brand: brand
}, []) => ["Enable Facial Recognition Login for Your ", brand.name, " Account."], {}]],
  ha52a2677428c0bd38ae96f2c4d5c49c2: [[({
  brand: brand
}, []) => ["You are about to enable Fingerprint Login for quick access to the ", brand.name, " mobile app as an alternative to your username and password."], {}]],
  hb473befed54ac90ff74ab24aedb1fa3f: [[({}, [C0, C1]) => ["Simply check the Enable", " ", C0(), " Login checkbox on the Login page and follow the instructions. If you haven\u2019t already, you will also need to enable", " ", C1(), " ", "in your device\u2019s settings."], {}]],
  hcafa4ec659331152b20f7e3f87e239a4: [[({}, []) => ["REMEMBER: Anyone with a stored fingerprint on this device will have access to your Online Account."], {}]],
  hdd90ab2875331a5dc233893f30d4dcbc: [[({}, [C0]) => [C0()], {}]] 
}