import { createElement, FC, MouseEvent, HTMLAttributes, useMemo } from 'react';
import { RawButton } from 'legos/typography';
import {
  ButtonBase as RawButtonBase,
  ButtonBaseCCM,
  ButtonBasePadding
} from './button-base.ccm.css';
import { ColorVariant } from './button-variant';
import { DefaultFonts } from 'legos/typography';

/**
 * These are the valid props for **every** button variant.
 */
export interface ButtonBaseProps {
  /**
   * Standard way to set the button look and feel.
   * @default default
   */
  color?: ColorVariant;
  /**
   * Set the button to disabled. Changes the look and color and makes the onClick not fire
   * @default "default"
   */
  disabled?: boolean;
  /**
   * Callback event to be notified when the button is clicked
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Href to set on the  event to be notified when the button is clicked.
   */
  href?: string;
  /**
   * Set the button type, useful to make it submit button
   * @default button
   */
  type?: JSX.IntrinsicElements['button']['type'];
  /**
   * Set the tab index
   * @default 0
   */
  tabIndex?: number;
  /**
   * Set the display type for button to block, useful for vartical set of buttons
   * @default false
   */
  block?: boolean;
  /**
   * Attribute for setting the aria-label. If your button text doesn't
   * adequately describe the action, **please** use this attribute and
   * be as verbose as possible.
   */
  label?: string;
  /**
   * Sets the target attribute on a link. This property is only valid when
   * `href` is set. If target is set to `_blank`, the `rel` attribute will
   * automatically get set to `noreferrer noopener` to to avoid exploitation
   * of the `window.opener` API.
   */
  target?: JSX.IntrinsicElements['a']['target'];
  /**
   * Uses the full width of the parent element.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Determines whether button will be styled built in preset margins
   * @default false
   */
  noMargins?: boolean;
  /**
   * Provides border radius to button
   * @default false
   */
  borderRadius?: boolean;
  backgroundTransparent?: string;
  // background?: any;
  backgroundDisabled?: string;
  textColorDisabled?: string;
  backgroundFocus?: string;
  backgroundDisabledFocus?: string;
  /**
   * Set the button id
   */
  id?: JSX.IntrinsicElements['button']['id'];
}

/**
 * Emulates the native `disabled` attribute by immediately cancelling any
 * events on the button. Natively disabled buttons have poor accessibility
 * due to not accepting focus, rendering buttons virtually invisible to screen
 * readers (with a few exceptions).
 */
const disableClick = (e: MouseEvent<HTMLButtonElement>) => {
  e.nativeEvent.stopImmediatePropagation();
};

/**
 * Base-level component for all button variants. This component is responbile
 * for interactions shared by all variants. Accessibility requirements should
 * be satisfied at this level as well.
 */
export const ButtonBase: FC<
  ButtonBaseProps & ButtonBaseCCM & HTMLAttributes<HTMLButtonElement>
> = ({
  block = false,
  children,
  className,
  disabled = false,
  href,
  label,
  onClick,
  style,
  tabIndex,
  target,
  type,
  // background,
  id
}) => {
  // sets the base primitive to either an anchor or a button
  const ButtonComponent = RawButtonBase.as(
    RawButton.as((href ? 'a' : 'button') as any)
  );

  // handle the cancellation of events when the button is disabled.
  const handleClick = useMemo(() => (disabled ? disableClick : onClick), [
    onClick,
    disabled
  ]);

  return (
    <ButtonComponent
      $block={block}
      // $background={background}
      aria-disabled={disabled}
      aria-label={label}
      className={className}
      href={href}
      onClick={handleClick}
      rel={href && target === '_blank' ? 'noreferrer noopener' : undefined}
      role="button"
      style={style}
      tabIndex={tabIndex}
      target={href && target ? target : undefined}
      type={type}
      id={id}
      $fontFace={DefaultFonts}
    >
      <ButtonBasePadding.span />
      {children}
    </ButtonComponent>
  );
};
