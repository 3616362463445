
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.DisclosureStyle_993cfcf902bb7924{color:#959595}.DisclosureWrap_0de97426737bdb2c{margin:0}`;
styleInject(_css)

/** DisclosureStyle Props */
export type DisclosureStyleCCM = {
  /** DisclosureStyle Component Custom Properties */
  // No custom properties found

  /** DisclosureStyle Modifier Flags */
  // No modifiers classes found
};
/** Base DisclosureStyle component */
export const DisclosureStyle: ComponentCreator<DisclosureStyleCCM> = createComponentCreator({
  "name": "DisclosureStyle",
  "base": "DisclosureStyle_993cfcf902bb7924",
  "prop": {},
  "mod": {}
});


/** DisclosureWrap Props */
export type DisclosureWrapCCM = {
  /** DisclosureWrap Component Custom Properties */
  // No custom properties found

  /** DisclosureWrap Modifier Flags */
  // No modifiers classes found
};
/** Base DisclosureWrap component */
export const DisclosureWrap: ComponentCreator<DisclosureWrapCCM> = createComponentCreator({
  "name": "DisclosureWrap",
  "base": "DisclosureWrap_0de97426737bdb2c",
  "prop": {},
  "mod": {}
});

