export default { 
  h4e56ab378c6d27b7fe604ef5fb242a8d: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h5f274858110a18ca90bcec2e50188640: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. The App Store is a registered trademark of Apple Inc."], {}]],
  h7b81c0f6bc5d943372f7cffc4e7d40cb: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h7fc9c1438b0ef4820b62a1c41d411728: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  h810504ddb35f82d99b236c280a399e1d: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  hb67db8cf2090b7abf9c18648ac425eed: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  hbb045fc152b2d68a94f42397779e00f0: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. The App Store is a registered trademark of Apple Inc."], {}]] 
}