import __NS_DYNAMIC_REACT_CONTENT___0 from "./unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure } from 'legos/typography';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { CurrentYear } from 'apps/acp/packages/date';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
import { DisclosureWrap } from './styles/paypal-unauthed-footer.ccm.css';
const isCordova = isAndroid() || isIOS();
const DisclosureWrapper = DisclosureWrap.as(Disclosure);
export const UnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "7fc9c1438b0ef4820b62a1c41d411728",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "b67db8cf2090b7abf9c18648ac425eed",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, "The Netspend Visa Prepaid Card is issued by Pathward\xAE, National Association, Republic Bank & Trust Company, and The Bancorp Bank, N.A.; Members FDIC, pursuant to a license from Visa U.S.A. Inc. The Netspend Prepaid Mastercard is issued by Pathward, N.A., Republic Bank & Trust Company, and The Bancorp Bank, N.A.; Members FDIC, pursuant to license by Mastercard International Incorporated. The Netspend\xAE Debit Account is a deposit account established by Pathward, N.A. The Netspend\xAE All-Access\xAE Account is a deposit account established by Republic Bank & Trust Company. Please see the back of your Card for its issuing bank. Ouro Global, Inc. is a registered agent of and service provider to Pathward, N.A., Republic Bank & Trust Company, and The Bancorp Bank, N.A.. The Netspend Visa Prepaid Card may be used everywhere Visa debit cards are accepted. The Netspend Prepaid Mastercard may be used everywhere Debit Mastercard is accepted. Transaction fees, terms, and conditions apply to the use and reloading of the Card Account. See the Cardholder Agreement for details."), createElement(Disclosure, null, "Mastercard and the circles design are registered trademarks of Mastercard International Incorporated."), mfeConfig.showShortAppleDisclosure && createElement(DisclosureWrapper, null, createElement(Content, {
    hash: "5f274858110a18ca90bcec2e50188640",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(DisclosureWrapper, null, createElement(Content, {
    hash: "bb045fc152b2d68a94f42397779e00f0",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(DisclosureWrapper, null, createElement(Content, {
    hash: "7b81c0f6bc5d943372f7cffc4e7d40cb",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "4e56ab378c6d27b7fe604ef5fb242a8d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, "*Wireless data rates may apply."), createElement(Disclosure, null, "\xA9 ", createElement(CurrentYear, null), " Ouro Global, Inc."), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "810504ddb35f82d99b236c280a399e1d",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};