//
// Implement all MFEs using the created injectables
//
import { encode } from 'packages/encode-base64';
import { createInjectable, MfeFactory } from 'packages/gateway/gateway';
import { createReactMfe } from 'packages/gateway/react-adapter';
import { Link } from 'packages/links';
import { AcpEnvironment } from 'apps/acp/packages/acp-config';
import { ActivationConfiguration } from 'apps/acp/micro-frontends/activate-card';
import {
  LoginConfiguration,
  LoginLinks,
  LoginConfigurationV2
} from 'apps/acp/micro-frontends/login';
import * as declarations from './declarations';
import {
  AcquireNewCustomer,
  backButtonHandlerPlugin,
  mount,
  bsAcpEnv,
  legoTheme,
  ErrorHandling,
  SuspenseHandling,
  bulkLegoTheme,
  gtmSendAnalytics,
  gtmAdobeLaunchSendAnalytics,
  externalLinkOpenerPlugin,
  formResponseTransformer,
  baseUrl,
  gatewayBaseUrl,
  webapiFetcher,
  webapiPermissionResolverSubscribe,
  declarativePermissionApi,
  declarativeAuthenticationApi,
  fileDownloaderPlugin,
  iovationPlugin,
  webapiAccessTokenDispatcher,
  sessionExpirationDispatcher,
  statusBarPlugin,
  Layout,
  UnauthedLayout,
  biometricsPlugin,
  logoLocator,
  printerPlugin,
  pushPlugin,
  isPreOnboardingEligible,
  dynatracePlugin,
  paypalMagnesPlugin,
  applePayPlugin,
  googlePayPlugin,
  samsungPayPlugin,
  atomicTransactPlugin,
  clipboardPlugin,
  apptentivePlugin,
  appsFlyerPlugin,
  adaChatbot,
  brazePlugin,
  UnauthedLayoutV2
} from './services';
import {
  POST_REDIRECT_KEY,
  POST_REDIRECT_PARAMETERS_KEY
} from './storage-keys';
import {
  DashboardLinks,
  DashboardConfiguration
} from 'apps/acp/micro-frontends/dashboard';
import {
  SpendingAccountLinks,
  SpendingAccountMfeConfiguration
} from 'apps/acp/micro-frontends/spending-account';
import {
  DirectDepositLinks,
  DirectDepositConfiguration
} from 'apps/acp/micro-frontends/direct-deposit';
import {
  ContactInfoConfiguration,
  ManageLinks
} from 'apps/acp/micro-frontends/manage';
import { CardConfiguration, CardLinks } from 'apps/acp/micro-frontends/card';
import { CloseAccountLinks } from 'apps/acp/micro-frontends/close-account';
import {
  ContactLinks,
  ContactUsConfiguration
} from 'apps/acp/micro-frontends/contact';
import { DynamicFAQLinks } from 'apps/acp/micro-frontends/dynamic-faq';
import { FeedbackLinks } from 'apps/acp/micro-frontends/feedback';
import { AnytimeAlertsLinks } from 'apps/acp/micro-frontends/anytime-alerts';
import { AnytimeAlertsUpgradeLinks } from 'apps/acp/micro-frontends/anytime-alerts-upgrade';
import { OverdraftLinks } from 'apps/acp/micro-frontends/overdraft';
import {
  BenefitCenterConfiguration,
  BenefitCenterLinks
} from 'apps/acp/micro-frontends/benefit-center';
import { BenefitCenterHebConfiguration } from 'apps/acp/micro-frontends/benefit-center-heb';
import {
  LocationsLinks,
  LocationsConfiguration
} from 'apps/acp/micro-frontends/locations';
import { SendReceiveMoneyLinks } from 'apps/acp/micro-frontends/send-receive-money';
import { OffersLinks } from 'apps/acp/micro-frontends/offers';
import { CashRewardsLink } from 'apps/acp/micro-frontends/cashrewards';
import { DirectOffersLink } from 'apps/acp/micro-frontends/direct-offers';
import { AtmsLinks } from 'apps/acp/micro-frontends/atms';
import { CheckDepositLinks } from 'apps/acp/micro-frontends/check-deposit';
import { SavingsLinks } from 'apps/acp/micro-frontends/savings';
import { MclLinks } from 'apps/acp/micro-frontends/mcl';
import { WUTransfersLinks } from 'apps/acp/micro-frontends/wu-transfers';
import { PaypalTransfersLinks } from 'apps/acp/micro-frontends/paypal-transfers';
import { BankTransfersLinks } from 'apps/acp/micro-frontends/bank-transfers';
import { ConnectedBanksLinks } from 'apps/acp/micro-frontends/connected-banks';
import { Me2MeTransfersLinks } from 'apps/acp/micro-frontends/me2me-transfers';
import { PayBillsLinks } from 'apps/acp/micro-frontends/pay-bills';
import { MoneygramLinks } from 'apps/acp/micro-frontends/moneygram';
import { SecureInboxLinks } from 'apps/acp/micro-frontends/secure-inbox';
import { ChecksLinks } from 'apps/acp/micro-frontends/checks';
import {
  SecurityConfiguration,
  SecurityLinks
} from 'apps/acp/micro-frontends/security';
import {
  DirectDepositTrackerLinks,
  DirectDepositTrackerConfiguration
} from 'apps/acp/micro-frontends/direct-deposit-tracker';
import {
  MoveMoneyConfiguration,
  MoveMoneyLinks
} from 'apps/acp/micro-frontends/move-money';
import {
  ManageAccountConfiguration,
  ManageAccountLinks
} from 'apps/acp/micro-frontends/manage-account';
import {
  UpgradeAccountConfiguration,
  UpgradeAccountLinks
} from 'apps/acp/micro-frontends/upgrade-account';
import { ActivateCardLinks } from 'apps/acp/micro-frontends/activate-card';
import { SpendTrackerLinks } from 'apps/acp/micro-frontends/spend-tracker';
import { AtmFinderLinks } from 'apps/acp/micro-frontends/atm-finder';
import {
  RecoverConfiguration,
  RecoverLinks
} from 'apps/acp/micro-frontends/recover';
import { FeeplanLinks } from 'apps/acp/micro-frontends/feeplan';
import { ReferAFriendLinks } from 'apps/acp/micro-frontends/refer-a-friend';
import { ExternalLoyaltyLinks } from 'apps/acp/micro-frontends/external-loyalty';
import {
  LoyaltyLinks,
  LoyaltyConfiguration
} from 'apps/acp/micro-frontends/loyalty';
import { LoyaltyLearnMoreLinks } from 'apps/acp/micro-frontends/loyalty-learn-more';
import { PaypalTransfersInfoLinks } from 'apps/acp/micro-frontends/paypal-transfers-info';
import { SpeedyRewardsLinks } from 'apps/acp/micro-frontends/speedy-rewards';
import { AlbertsonsDollarsLinks } from 'apps/acp/micro-frontends/albertsons-dollars';
import { DigitalWalletLinks } from 'apps/acp/micro-frontends/digital-wallet';
import {
  DebitCardTransferFundsLinks,
  DebitCardTransferFundsConfiguration
} from 'apps/acp/micro-frontends/debit-card-transfer-funds';
import { SkylightChecksLinks } from 'apps/acp/micro-frontends/skylight-checks';
import { bsSwitchMap } from 'packages/behavior-subject/fn';
import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';
import React from 'react';
import { InterstitialsLinks } from 'apps/acp/micro-frontends/interstitials';
import {
  PreOnboardingConfiguration,
  PreOnboardingLinks
} from 'apps/acp/micro-frontends/pre-onboarding';
import {
  CashbackLinks,
  CashbackConfiguration
} from 'apps/acp/micro-frontends/cashback';
import {
  PaypalConfiguration,
  PaypalAuthLinks
} from 'apps/acp/micro-frontends/paypal-rest-auth';
import { NotificationsConfiguration } from 'apps/acp/micro-frontends/notifications';
import { SecureUploadLinks } from 'apps/acp/micro-frontends/secure-upload';
import { SecureCreditCardConfiguration } from 'apps/acp/micro-frontends/secure-credit-card';
import { RentTrackLinks } from 'apps/acp/micro-frontends/rent-track';
import { DisputeHubLink } from 'apps/acp/micro-frontends/dispute-hub-tracker';
import { HandOffLinks } from 'apps/acp/micro-frontends/handoff';
import { LinkExternalAccountSuccessLinks } from 'apps/acp/micro-frontends/link-external-account-success';
import { DormantAccountLink } from 'apps/acp/micro-frontends/dormant-account';
import { PremierPlanLinks } from 'apps/acp/micro-frontends/premier-plan';
import { FraudDetectionLinks } from 'apps/acp/micro-frontends/fraud-detection';
import { getUrlSearchParams } from 'packages/url-parser';
import { loginPermissions } from './grouped-permissions';
import { SelfEnrollmentConfiguration } from 'apps/acp/micro-frontends/self-enrollment';
import {
  ACP_Next_Cookie,
  checkCookieExist,
  deleteCookie
} from './util/cookie-helper';
import { SpectrumLoginLinks } from '../micro-frontends/spectrum-login';
import { SpectrumDashboardLinks } from '../micro-frontends/spectrum-dashboard';

const commonMfeDependencies = {
  bsAcpEnv,
  backButtonHandlerPlugin,
  baseUrl,
  bulkLegoTheme,
  ErrorHandling,
  externalLinkOpenerPlugin,
  fetcher: webapiFetcher,
  formResponseTransformer,
  legoTheme,
  mount,
  sendAnalytics: gtmSendAnalytics,
  SuspenseHandling,
  pushPlugin,
  brazePlugin,
  apptentivePlugin,
  adaChatbot
} as const;

const createMfeToNgInterop = <Links extends object>(
  createAppLinks: (root: Link) => Links,
  cookieName?: string
): MfeFactory<Links> => (rootLink) => ({
  links: createAppLinks(rootLink.root),
  mount({ location }) {
    // Ensure we turn on the cookie for the reload
    document.cookie = `${cookieName}=1;path=/;max-age=1200`;
    location.reload();

    // We need to indicate to the gateway that we are not done loading our MFE
    // We require an entirely new document load in order to finish loading our MFE,
    // So we return a promise that never finishes
    return new Promise(() => null);
  },
  unmount() {
    // noop
  }
});

const createMfeToSpectrumInterop = <Links extends object>(
  createAppLinks: (root: Link) => Links,
  cookieName?: string
): MfeFactory<Links> => (rootLink) => ({
  links: createAppLinks(rootLink.root),
  mount({ location }) {
    // Ensure we turn on the cookie for the reload
    // TODO Tech-debt: https://nrbx.atlassian.net/browse/NOAC-430
    //  Use bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment> to use variant
    //  config to determine if the cookie should be removed.  This is necessary
    //  to prevent an infinite reload situation.
    if (
      location.host &&
      (location.host.includes('www.') || location.host.includes('www-'))
    ) {
      document.cookie = `${cookieName}=1;path=/;max-age=0`;
    }

    location.reload();

    // We need to indicate to the gateway that we are not done loading our MFE
    // We require an entirely new document load in order to finish loading our MFE,
    // So we return a promise that never finishes
    return new Promise(() => null);
  },
  unmount() {
    // noop
  }
});

const createMfeRedirector = (
  url: string,
  replace: boolean | string = false,
  cookie?: string
): MfeFactory<any> => () => ({
  links: null as any,
  mount({ location }) {
    if (cookie && !checkCookieExist(cookie)) {
      return new Promise(() => null);
    }
    if (cookie && checkCookieExist(cookie)) deleteCookie(cookie);
    if (replace) location.replace(typeof replace === 'string' ? replace : url);
    else location.assign(url);
  },
  unmount() {
    // noop
  }
});

const createPostLoginRedirector = (loginLinks: LoginLinks) => {
  sessionStorage.setItem(POST_REDIRECT_KEY, encode(location.pathname));

  // url search params save in session
  const params = getUrlSearchParams<string>();
  if (params) {
    sessionStorage.setItem(
      POST_REDIRECT_PARAMETERS_KEY,
      window.btoa(JSON.stringify(params))
    );
  }

  return createMfeRedirector(loginLinks.main.url());
};

const createActivationMfeConfig = (isRegisterFlow: boolean) =>
  createInjectable<
    BehaviorSubjectSubscribe<ActivationConfiguration>,
    {
      bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
      ErrorHandling: React.FC;
    }
  >(
    () => ({ bsAcpEnv, ErrorHandling }) => {
      return bsSwitchMap(
        bsAcpEnv,
        async (acpEnv) => {
          return {
            ...acpEnv.config,
            offlineBranding: acpEnv.branding,
            isRegisterFlow
          };
        },
        () => ErrorHandling
      );
    },
    { bsAcpEnv, ErrorHandling }
  );

const activationMfeToNgInterop = createMfeToNgInterop(
  (root): ActivateCardLinks => ({
    main: root,
    enterCardlessFlow: root, // Not implemented in ngACP
    // eslint-disable-next-line @netspend/link-slashes
    selfEnrollmentRegister: root.extend`/selfEnrollment`(),
    // eslint-disable-next-line @netspend/link-slashes
    selfEnrollmentActivate: root.extend`/selfEnrollment`()
  }),
  'ACP_NG'
);

// Ensure /activate/additional-card is always loaded in angularjs until PIC-7634 is implemented
// Allow the handoff routes to bypass the CARE rule to get to activation-mfe
//   This is necessary for the ng-activation -> activation-mfe handoff flow which can switch domains, and `permissions()` does not use the cvc-authed access_token which initiated the handoff on the angular side
const activationMfeIneligibilityCheckerInjectable = createInjectable(
  () => ({ permissions }) => async () => {
    return (
      ((await permissions({ 'webapi:reactivation_eligible': false })) &&
        !(
          // For handoff's via a new page load, (ie checking human readable link)
          (
            location.href.match(
              /\/(activate|register)\/(handoff|cardless-handoff)/
            ) ||
            // For handoff's via nano-router (ie checking a gateway injected link)
            location.href.match('activation/enterCardlessFlow')
          )
        )) ||
      location.href.includes('/activate/additional-card')
    );
  },
  {
    permissions: declarativePermissionApi
  }
);

const commonActivateDependencies = {
  AcquireNewCustomer,
  contact: declarations.contact,
  dashboardLinks: declarations.dashboard,
  loginLinks: declarations.login,
  logoutLinks: declarations.logout,
  recover: declarations.recover,
  upgradeAccountLinks: declarations.upgradeAccount,
  permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
  sendAnalytics: gtmAdobeLaunchSendAnalytics,
  iovationPlugin,
  statusBarPlugin,
  logoLocator,
  selfEnrollmentLinks: declarations.selfEnrollment,
  locationFinderLinks: declarations.locationFinder
};

declarations.activateCard
  .implement(
    // If not activation-mfe-eligible, load ngAcp
    createInjectable(() => () => activationMfeToNgInterop),
    activationMfeIneligibilityCheckerInjectable
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/activate-card/implementation/v2')
      ),
      {
        ...commonMfeDependencies,
        ...commonActivateDependencies,
        appsFlyerPlugin,
        mfeConfig: createActivationMfeConfig(false)
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          // This marketing element is used for redesign of activate-card module
          'oac:activate': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/activate-card/implementation/v1')
      ),
      {
        ...commonMfeDependencies,
        ...commonActivateDependencies,
        appsFlyerPlugin,
        mfeConfig: createActivationMfeConfig(false)
      }
    )
  );

declarations.register
  .implement(
    // If not activation-mfe-eligible, load ngAcp
    createInjectable(() => () => activationMfeToNgInterop),
    createInjectable(
      () => () => async () => {
        return false;
      },
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/activate-card/implementation/v1')
      ),
      {
        ...commonMfeDependencies,
        ...commonActivateDependencies,
        appsFlyerPlugin,
        mfeConfig: createActivationMfeConfig(true)
      }
    )
  );

const paypalMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<PaypalConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          statusBarBackgroundColor,
          paypalFraudNetPayerId,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          importantPatriotActDisclosure,
          showAcquisitionLink,
          showRegisterLink,
          showMarketingSiteLink,
          showFreeAtmFinderLink,
          marketingSiteUrl,
          marketingSiteLinkText,
          openAccountFooterContent,
          showReloadLocationLink,
          programType,
          platformType,
          version
        } = acpEnv.config;
        return {
          statusBarBackgroundColor,
          paypalFraudNetPayerId,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          importantPatriotActDisclosure,
          showAcquisitionLink,
          showRegisterLink,
          showMarketingSiteLink,
          showFreeAtmFinderLink,
          marketingSiteUrl,
          marketingSiteLinkText,
          openAccountFooterContent,
          showReloadLocationLink,
          programType,
          platformType,
          version
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.paypalAuthenticate
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/paypal-rest-auth/implementation')
      ),
      {
        ...commonMfeDependencies,
        paypalMagnesPlugin: paypalMagnesPlugin,
        mfeConfig: paypalMfeConfig,
        logoLocator,
        loginLinks: declarations.login,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        iovationPlugin,
        dashboardLinks: declarations.dashboard,
        handOffLinks: declarations.handOff,
        contactLinks: declarations.contact,
        activateCardLinks: declarations.activateCard,
        registerLinks: declarations.register,
        atmFinderLinks: declarations.atmFinder,
        logOutLinks: declarations.logout,
        locationFinderLinks: declarations.locationFinder,
        AcquireNewCustomer,
        LinkExternalAccountSuccessLinks: declarations.linkExternalAccountSuccess
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:login_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): PaypalAuthLinks => ({
          main: root,
          // eslint-disable-next-line @netspend/link-slashes
          authWithParams: root.extend`?code&error`()
        }),
        'ACP_NG'
      )
    )
  );

const loginMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<LoginConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          customizedLoginPage,
          esignProductNameContent,
          marketingSiteUrl,
          showAcquisitionLink,
          appsFlyerBannerKey,
          signUpContent,
          openAccountButtonContent,
          signUpDisclaimerContent
        } = acpEnv.config;
        return {
          customizedLoginPage,
          esignProductNameContent,
          marketingSiteUrl,
          showAcquisitionLink,
          appsFlyerBannerKey,
          signUpContent,
          openAccountButtonContent,
          signUpDisclaimerContent
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

const loginMfeConfigv2 = createInjectable<
  BehaviorSubjectSubscribe<LoginConfigurationV2>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          customizedLoginPage,
          esignProductNameContent,
          marketingSiteUrl,
          showAcquisitionLink,
          appsFlyerBannerKey,
          signUpContent,
          openAccountButtonContent,
          signUpDisclaimerContent,
          showRegisterLink,
          showReloadLocationLink,
          showFreeAtmFinderLink,
          openAccountFooterContent,
          showMarketingSiteLink,
          marketingSiteLinkText
        } = acpEnv.config;
        return {
          customizedLoginPage,
          esignProductNameContent,
          marketingSiteUrl,
          showAcquisitionLink,
          appsFlyerBannerKey,
          signUpContent,
          openAccountButtonContent,
          signUpDisclaimerContent,
          showRegisterLink,
          showReloadLocationLink,
          showFreeAtmFinderLink,
          openAccountFooterContent,
          showMarketingSiteLink,
          marketingSiteLinkText
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.login
  .implement(
    createInjectable(() => () =>
      createMfeToSpectrumInterop(
        (root): SpectrumLoginLinks => ({
          main: root,
          // eslint-disable-next-line @netspend/link-slashes
          loginWithReason: root.extend`?reason=session-timeout`(),
          authorize: root,
          ooba: root,
          troobaOrderCardLink: root,
          idCheckWithoutDocLink: root
        }),
        ACP_Next_Cookie
      )
    ),
    createInjectable(
      () => () => async () => {
        return document.cookie.includes(ACP_Next_Cookie);
      },
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeRedirector('/account/pre-onboarding', true)
    ),
    createInjectable(
      () => ({ isPreOnboardingEligible }) => () => isPreOnboardingEligible,
      { isPreOnboardingEligible }
    )
  )
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/dashboard')),
    createInjectable(
      () => ({ authenticationApi }) => async () =>
        await authenticationApi({ password: true, authorization_blocks: [] }),
      { authenticationApi: declarativeAuthenticationApi }
    )
  )
  // this is a dummy and will fail but this helps in reducing the number of calls to features api and provides all the features
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/login')),
    createInjectable(
      () => ({ permissions }) => async () =>
        // this contains all the permissions required in login page
        await permissions(loginPermissions),
      {
        permissions: declarativePermissionApi
      }
    )
  )

  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/login/implementation/v2')
      ),
      {
        ...commonMfeDependencies,
        AcquireNewCustomer,
        appsFlyerPlugin,
        mfeConfigV2: loginMfeConfigv2,
        sessionExpirationDispatcher: sessionExpirationDispatcher,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        dashboardLinks: declarations.dashboard,
        cardLinks: declarations.card,
        activateCardLinks: declarations.activateCard,
        registerLinks: declarations.register,
        recoverLinks: declarations.recover,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        sendAnalytics: gtmAdobeLaunchSendAnalytics,
        biometricsPlugin: biometricsPlugin,
        iovationPlugin,
        logOutLinks: declarations.logout,
        UnauthedLayoutV2,
        UnauthedLayout,
        locationFinderLinks: declarations.locationFinder,
        contactLinks: declarations.contact,
        atmFinderLinks: declarations.atmFinder,
        gatewayBaseUrl,
        interstitialsLinks: declarations.interstitials,
        dynatracePlugin: dynatracePlugin,
        paypalRestAuthLinks: declarations.paypalAuthenticate,
        dormantAccountLink: declarations.dormantAccount,
        selfEnrollmentLinks: declarations.selfEnrollment,
        secureUploadLinks: declarations.secureUpload
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'oac:login_redesign': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/login/implementation/v1')
      ),
      {
        ...commonMfeDependencies,
        AcquireNewCustomer,
        appsFlyerPlugin,
        mfeConfig: loginMfeConfig,
        sessionExpirationDispatcher: sessionExpirationDispatcher,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        dashboardLinks: declarations.dashboard,
        cardLinks: declarations.card,
        activateCardLinks: declarations.activateCard,
        registerLinks: declarations.register,
        recoverLinks: declarations.recover,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        sendAnalytics: gtmAdobeLaunchSendAnalytics,
        biometricsPlugin: biometricsPlugin,
        iovationPlugin,
        logOutLinks: declarations.logout,
        UnauthedLayout,
        gatewayBaseUrl,
        interstitialsLinks: declarations.interstitials,
        dynatracePlugin: dynatracePlugin,
        paypalRestAuthLinks: declarations.paypalAuthenticate,
        dormantAccountLink: declarations.dormantAccount,
        selfEnrollmentLinks: declarations.selfEnrollment,
        secureUploadLinks: declarations.secureUpload
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:login_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): LoginLinks => ({
          main: root,
          // eslint-disable-next-line @netspend/link-slashes
          loginWithReason: root.extend`?reason=session-timeout`(),
          authorize: root.extend`/authorize`(),
          ooba: root.extend`/ooba`(),
          troobaOrderCardLink: root.extend`/trooba-order-card`(),
          idCheckWithoutDocLink: root.extend`/id-verification-required`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.leanDispute.implement(
  createInjectable(
    createReactMfe(() =>
      import('apps/acp/micro-frontends/lean-dispute/implementation')
    ),
    {
      ...commonMfeDependencies,
      logoLocator,
      cardLinks: declarations.card,
      appsFlyerPlugin,
      dashboardLinks: declarations.dashboard
    }
  )
);

const dashboardMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<DashboardConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          brand,
          programType,
          appVersion,
          statusBarBackgroundColor,
          statusBarForegroundColor,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          accountTypeText,
          fundAccountText,
          mastercardStatement
        } = acpEnv.config;
        return {
          brand,
          programType,
          appVersion,
          statusBarBackgroundColor,
          statusBarForegroundColor,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          accountTypeText,
          fundAccountText,
          mastercardStatement
        } as DashboardConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.dashboard
  .implement(
    createInjectable(() => () =>
      createMfeToSpectrumInterop(
        (root): SpectrumDashboardLinks => ({
          main: root,
          cipAdditionalPage: root,
          selfEnrollmentDashboard: root
        }),
        ACP_Next_Cookie
      )
    ),
    createInjectable(
      () => () => async () => {
        return document.cookie.includes(ACP_Next_Cookie);
      },
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/dashboard/implementation/v1')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: dashboardMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        sendAnalytics: gtmAdobeLaunchSendAnalytics,
        savingsLinks: declarations.savings,
        spendingAccountLinks: declarations.spendingAccount,
        checkDepositLinks: declarations.checkDeposit,
        notificationsLinks: declarations.notifications,
        loyaltyLinks: declarations.loyalty,
        loyaltyLearnMoreLinks: declarations.loyaltyLearnMore,
        externalLoyaltyLinks: declarations.externalLoyalty,
        directDepostTrackerLinks: declarations.directDepositTracker,
        paypalTransfersLinks: declarations.paypalTransfers,
        cardLinks: declarations.card,
        cryptoLinks: declarations.crypto,
        digitalWalletLinks: declarations.digitalWallet,
        upgradeAccountLinks: declarations.upgradeAccount,
        referAFriendLinks: declarations.referAFriend,
        spendTrackerLinks: declarations.spendTracker,
        offersLinks: declarations.offers,
        cashRewardsLink: declarations.cashRewards,
        directDepositLinks: declarations.directDeposit,
        anytimeAlertsLinks: declarations.anytimeAlerts,
        locationsLinks: declarations.locations,
        bankTransfersLinks: declarations.bankTransfers,
        moveMoneyLinks: declarations.moveMoney,
        cashbackLinks: declarations.cashback,
        secureUploadLinks: declarations.secureUpload,
        statusBarPlugin: statusBarPlugin,
        dynamicFAQLinks: declarations.dynamicFAQ,
        disputeHubLink: declarations.disputeHubTracker,
        debitCardTransferLink: declarations.debitCardTransferFunds,
        clipboardPlugin: clipboardPlugin,
        applePayPlugin: applePayPlugin,
        googlePayPlugin: googlePayPlugin,
        samsungPayPlugin: samsungPayPlugin,
        appsFlyerPlugin,
        secureCreditCardLinks: declarations.secureCreditCard,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:rising_tide_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DashboardLinks => ({
          main: root,
          cipAdditionalPage: root.extend`/cip-additional-page`(),
          selfEnrollmentDashboard: root.extend`/self-enrollment`()
        }),
        'ACP_NG'
      )
    )
  );

const spendingAccountMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<SpendingAccountMfeConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          copyrightStatement,
          accountTypeText,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer
        } = acpEnv.config;
        return {
          offlineBranding: acpEnv.branding,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          accountTypeText
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.spendingAccount
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/spending-account/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: spendingAccountMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        dashboardLinks: declarations.dashboard,
        manageLinks: declarations.manage,
        cardsLinks: declarations.card,
        locationsLinks: declarations.locations,
        checkDepositLinks: declarations.checkDeposit,
        directDepositLinks: declarations.directDeposit,
        bankTransfersLinks: declarations.bankTransfers,
        savingsLinks: declarations.savings,
        loyaltyLinks: declarations.loyalty,
        moveMoneyLinks: declarations.moveMoney,
        manageAccountLinks: declarations.manageAccount,
        Layout,
        cashbackLinks: declarations.cashback,
        spendTrackerLinks: declarations.spendTracker,
        appsFlyerPlugin,
        benefitCenterLinks: declarations.benefitCenter
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:rising_tide_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): SpendingAccountLinks => ({
          main: root,
          debit: root.extend`/debit`(),
          savings: root.extend`/savings`(),
          points: root.extend`/points`(),
          transactionDetails: root.extend`/details`(),
          loyaltyTransactionDetails: root.extend`/points/dashboard/details`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.spendTracker
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/spend-tracker/implementation')
      ),
      {
        ...commonMfeDependencies,
        appsFlyerPlugin
      }
    ),

    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:spending_tracker_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): SpendTrackerLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const directDepositTrackerMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<DirectDepositTrackerConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { brand, programType } = acpEnv.config;
        return { brand, programType };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);
declarations.directDepositTracker
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/direct-deposit-tracker/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: directDepositTrackerMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout,
        dashboardLinks: declarations.dashboard,
        notificationsLinks: declarations.notifications,
        appsFlyerPlugin,
        anytimeAlertLinks: declarations.anytimeAlerts
      }
    ),

    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ direct_deposit_tracker_enabled: true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DirectDepositTrackerLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const debitCardTransferFundsMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<DebitCardTransferFundsConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { programType } = acpEnv.config;
        return { programType };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);
declarations.debitCardTransferFunds
  .implement(
    createInjectable(() => () =>
      createMfeRedirector('/account/dashboard', true)
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        (await permissions({
          CIPConditionalDocumentsNotUploaded: true
        })) ||
        (await permissions({
          CIPConditionalDocumentsUploaded: true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import(
          'apps/acp/micro-frontends/debit-card-transfer-funds/implementation'
        )
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: debitCardTransferFundsMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        dashboardLinks: declarations.dashboard,
        moveMoneyLinks: declarations.moveMoney,
        logoutLinks: declarations.logout,
        clipboardPlugin: clipboardPlugin,
        appsFlyerPlugin,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'webapi:debit_card_transfer_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DebitCardTransferFundsLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const moveMoneyMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<MoveMoneyConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          programType,
          appVersion,
          locationFinderLinkText,
          sendReceiveMoneyText,
          sendReceiveMoneyIcon
        } = acpEnv.config;
        return {
          programType,
          appVersion,
          locationFinderLinkText,
          sendReceiveMoneyText,
          sendReceiveMoneyIcon
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.moveMoney
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/move-money/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: moveMoneyMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        directDepositLinks: declarations.directDeposit,
        locationsLinks: declarations.locations,
        sendReceiveMoneyLinks: declarations.sendReceiveMoney,
        atmsLinks: declarations.atms,
        cryptoLinks: declarations.crypto,
        savingsLinks: declarations.savings,
        checkDepositLinks: declarations.checkDeposit,
        mclLinks: declarations.mcl,
        me2meTransfersLinks: declarations.me2meTransfers,
        bankTransfersLinks: declarations.bankTransfers,
        paypalTransfersLinks: declarations.paypalTransfers,
        paypalTransfersInfoLinks: declarations.paypalTransfersInfo,
        wuTransfersLinks: declarations.wuTransfers,
        payBillsLinks: declarations.payBills,
        moneygramLinks: declarations.moneygram,
        checksLinks: declarations.checks,
        dashboardLinks: declarations.dashboard,
        Layout,
        notificationsLinks: declarations.notifications,
        debitCardTransferFundsLinks: declarations.debitCardTransferFunds,
        skylightChecksLinks: declarations.skylightChecks,
        appsFlyerPlugin
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): MoveMoneyLinks => ({
          main: root,
          addCheck: root.extend`/add-check`(),
          waysToAddMoney: root.extend`/ways-to-add-money`()
        }),
        'ACP_NG'
      )
    )
  );

const directDepositMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<DirectDepositConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer
        } = acpEnv.config;
        return {
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer
        } as DirectDepositConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.directDeposit
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/direct-deposit/implementation')
      ),
      {
        ...commonMfeDependencies,
        atomicTransactPlugin,
        clipboardPlugin,
        Layout,
        dashboardLinks: declarations.dashboard,
        activateCardLinks: declarations.activateCard,
        premierPlanLinks: declarations.premierPlan,
        mfeConfig: directDepositMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        appsFlyerPlugin,
        printerPlugin
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        !location.href.includes('/direct-deposit-form') &&
        (await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    // Because of a gateway bug, path /direct-deposit-form activates the direct-deposit MFE
    createInjectable(() => () =>
      createMfeRedirector('/account/direct-deposit/form-dialog', true)
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        location.href.includes('/direct-deposit-form') &&
        (await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeRedirector('/account/direct-deposit-form', true)
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        location.href.includes('/direct-deposit/form-dialog') &&
        !(await permissions({
          'acp:directdeposit_mfe_eligible': true
        })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DirectDepositLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const manageAccountMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<ManageAccountConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          programType,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          accountTypeText,
          benefitsCenterTitle
        } = acpEnv.config;
        return {
          programType,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          accountTypeText,
          benefitsCenterTitle
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.manageAccount
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/manage-account/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: manageAccountMfeConfig,
        directDepositLinks: declarations.directDeposit,
        manageLinks: declarations.manage,
        cardLinks: declarations.card,
        closeAccountLinks: declarations.closeAccount,
        contactLinks: declarations.contact,
        dynamicFAQLinks: declarations.dynamicFAQ,
        feedbackLinks: declarations.feedback,
        leanDisputeLinks: declarations.leanDispute,
        logOutLinks: declarations.logout,
        benefitCenterLinks: declarations.benefitCenter,
        anytimeAlertLinks: declarations.anytimeAlerts,
        overdraftLinks: declarations.overdraft,
        savingsLinks: declarations.savings,
        spendTrackerLinks: declarations.spendTracker,
        secureInboxLinks: declarations.secureInbox,
        dashboardLinks: declarations.dashboard,
        spendingAccountLinks: declarations.spendingAccount,
        securityLinks: declarations.security,
        loyaltyLinks: declarations.loyalty,
        loyaltyLearnMoreLinks: declarations.loyaltyLearnMore,
        loyaltyExternalLinks: declarations.externalLoyalty,
        connectedBanksLinks: declarations.connectedBanks,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout,
        fileDownloaderPlugin: fileDownloaderPlugin,
        feeplanLinks: declarations.feeplan,
        upgradeAccountLinks: declarations.upgradeAccount,
        referAFriendLinks: declarations.referAFriend,
        notificationsLinks: declarations.notifications,
        cashbackLinks: declarations.cashback,
        disputeHubLink: declarations.disputeHubTracker,
        offersLinks: declarations.offers,
        cashrewardLinks: declarations.cashRewards,
        appsFlyerPlugin,
        esignLink: declarations.esign
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): ManageAccountLinks => ({
          main: root,
          helpAndSupportLink: root.extend`/help-support`(),
          debitStatementsLink: root.extend`/statements/debit`(),
          savingsStatementsLink: root.extend`/statements/savings`()
        }),
        'ACP_NG'
      )
    )
  );

const cashbackMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<CashbackConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { programType, fivePercentDisclaimer } = acpEnv.config;
        return { programType, fivePercentDisclaimer } as CashbackConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.cashback
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/cashback/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: cashbackMfeConfig,
        spendingAccountLinks: declarations.spendingAccount,
        moveMoneyLinks: declarations.moveMoney,
        Layout,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): CashbackLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const contactInfoMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<ContactInfoConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { copyrightStatement } = acpEnv.config;
        return { copyrightStatement } as ContactInfoConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.manage
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/manage/implementation')
      ),
      {
        ...commonMfeDependencies,
        manageAccountLinks: declarations.manageAccount,
        Layout,
        appsFlyerPlugin,
        mfeConfig: contactInfoMfeConfig
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:contact_info_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): ManageLinks => ({
          main: root,
          profile: root.extend`/profile`()
        }),
        'ACP_NG'
      )
    )
  );

const cardMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<CardConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          brand,
          programType,
          platform,
          platformType,
          appName,
          version,
          variant,
          patriotActDisclosure
        } = acpEnv.config;
        return {
          brand,
          programType,
          platform,
          platformType,
          appName,
          version,
          variant,
          patriotActDisclosure
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.card
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/card/implementation')
      ),
      {
        ...commonMfeDependencies,
        activateCard: declarations.activateCard,
        dashboardLinks: declarations.dashboard,
        logOutLinks: declarations.logout,
        mfeConfig: cardMfeConfig,
        Layout,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        applePayPlugin: applePayPlugin,
        googlePayPlugin: googlePayPlugin
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:cardlanding_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): CardLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.closeAccount
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/close-account/implementation')
      ),
      {
        ...commonMfeDependencies,
        cardLinks: declarations.card,
        manageAccountLinks: declarations.manageAccount,
        dashboardLinks: declarations.dashboard,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:close_account_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): CloseAccountLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );
const contactMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<ContactUsConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          contactRecipient,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          importantPatriotActDisclosure,
          marketingSiteUrl,
          marketingSiteLinkText,
          showMarketingSiteLink,
          showAcquisitionLink,
          showReloadLocationLink,
          showFreeAtmFinderLink,
          openAccountFooterContent,
          programType,
          version,
          statusBarBackgroundColor
        } = acpEnv.config;
        const {
          phone_international,
          phone,
          phone_formatted,
          fax_formatted,
          contact_address_line1,
          contact_address_line2,
          customer_support_email
        } = acpEnv.branding;
        return {
          contactRecipient,
          phone_international,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          importantPatriotActDisclosure,
          customer_support_email,
          contact_address_line1,
          contact_address_line2,
          marketingSiteUrl,
          marketingSiteLinkText,
          phone,
          phone_formatted,
          fax_formatted,
          showMarketingSiteLink,
          showAcquisitionLink,
          showReloadLocationLink,
          showFreeAtmFinderLink,
          openAccountFooterContent,
          programType,
          version,
          statusBarBackgroundColor
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.contact
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/contact/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: contactMfeConfig,
        dynamicFAQLinks: declarations.dynamicFAQ,
        manageAccountLinks: declarations.manageAccount,
        secureInboxLinks: declarations.secureInbox,
        activateCardLinks: declarations.activateCard,
        loginLinks: declarations.login,
        logoutLinks: declarations.logout,
        locationFinderLinks: declarations.locationFinder,
        atmFinderLinks: declarations.atmFinder,
        Layout,
        logoLocator,
        AcquireNewCustomer,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({ 'acp:contact_mfe_eligible': true }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): ContactLinks => ({
          main: root,
          precontact: root.extend`/precontact`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.dynamicFAQ
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/dynamic-faq/implementation')
      ),
      {
        ...commonMfeDependencies,
        Layout,
        UnauthedLayout,
        AcquireNewCustomer,
        contactLinks: declarations.contact,
        appsFlyerPlugin
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:dynamic_faq_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DynamicFAQLinks => ({
          main: root,
          search: root.extend`/search?query=${'searchText'}`(),
          question: root.extend`/question?questionid=${'questionid'}&categoryid=${'categoryid'}`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.feedback.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): FeedbackLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.anytimeAlerts.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): AnytimeAlertsLinks => ({
        main: root,
        intro: root.extend`/intro`()
      }),
      'ACP_NG'
    )
  )
);

declarations.anytimeAlertsUpgrade.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): AnytimeAlertsUpgradeLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.overdraft.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): OverdraftLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const benefitCenterHebMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<BenefitCenterHebConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          optionalSavingsDisclaimer,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          rideShareDisclaimer,
          cellPhoneProtectionDisclaimer,
          idProtectionDisclaimer,
          atmDisclaimer,
          directDepositDisclaimer,
          participationFeeDisclaimer,
          copyrightStatement,
          fivePercentDisclaimer
        } = acpEnv.config;
        return {
          optionalSavingsDisclaimer,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          rideShareDisclaimer,
          cellPhoneProtectionDisclaimer,
          idProtectionDisclaimer,
          atmDisclaimer,
          directDepositDisclaimer,
          participationFeeDisclaimer,
          copyrightStatement,
          fivePercentDisclaimer
        } as BenefitCenterHebConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

const benefitCenterMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<BenefitCenterConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          mastercardStatement
        } = acpEnv.config;
        return {
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          mastercardStatement
        } as BenefitCenterConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.benefitCenter
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/benefit-center-heb/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: benefitCenterHebMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout,
        locationsLinks: declarations.locations,
        atmsLinks: declarations.atms,
        directDepositLinks: declarations.directDeposit,
        savingsLinks: declarations.savings,
        spendingAccountLinks: declarations.spendingAccount,
        appsFlyerPlugin,
        manageAccountLinks: declarations.manageAccount
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:rt_benefits_center_eligible': true,
          'webapi:is_heb_debit': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/benefit-center/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: benefitCenterMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout,
        appsFlyerPlugin,
        manageAccountLinks: declarations.manageAccount
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:rt_benefits_center_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): BenefitCenterLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.speedyRewards.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): SpeedyRewardsLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.albertsonsDollars.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): AlbertsonsDollarsLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.sendReceiveMoney.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): SendReceiveMoneyLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.atms.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): AtmsLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.savings.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): SavingsLinks => ({
        main: root,
        enroll: root.extend`/enroll`()
      }),
      'ACP_NG'
    )
  )
);

const notificationsMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<NotificationsConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        return {
          offlineBranding: acpEnv.branding
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.notifications.implement(
  createInjectable(
    createReactMfe(() =>
      import('apps/acp/micro-frontends/notifications/implementation')
    ),
    {
      ...commonMfeDependencies,
      directDepositLinks: declarations.directDeposit,
      mfeConfig: notificationsMfeConfig,
      upgradeAccountLinks: declarations.upgradeAccount,
      offersLinks: declarations.offers,
      cashRewardsLink: declarations.cashRewards,
      dashboardLinks: declarations.dashboard,
      digitalWalletLinks: declarations.digitalWallet,
      referAFriendLinks: declarations.referAFriend,
      applePayPlugin: applePayPlugin,
      googlePayPlugin: googlePayPlugin,
      samsungPayPlugin: samsungPayPlugin,
      appsFlyerPlugin,
      Layout
    }
  )
);

declarations.mcl.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): MclLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.checkDeposit.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): CheckDepositLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.me2meTransfers.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): Me2MeTransfersLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.bankTransfers.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): BankTransfersLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.paypalTransfers.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): PaypalTransfersLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.paypalTransfersInfo.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): PaypalTransfersInfoLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.wuTransfers.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): WUTransfersLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.payBills.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): PayBillsLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.moneygram.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): MoneygramLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.checks.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): ChecksLinks => ({
        main: root.extend`/order`(),
        prefund: root.extend`/pre-fund`(),
        manage: root.extend`/manage`()
      }),
      'ACP_NG'
    )
  )
);

declarations.logout.implement(
  createInjectable(
    createReactMfe(() =>
      import('apps/acp/micro-frontends/logout/implementation')
    ),
    {
      ...commonMfeDependencies,
      loginLinks: declarations.login,
      accessTokenDispatcher: webapiAccessTokenDispatcher
    }
  )
);

declarations.secureInbox.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): SecureInboxLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const securityMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<SecurityConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { esignProductNameContent } = acpEnv.config;
        return {
          esignProductNameContent
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.security
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/security/implementation')
      ),
      {
        ...commonMfeDependencies,
        manageAccountLinks: declarations.manageAccount,
        logOutLinks: declarations.logout,
        biometricsPlugin: biometricsPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        mfeConfig: securityMfeConfig,
        appsFlyerPlugin,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:security_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): SecurityLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.atmFinder.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): AtmFinderLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const recoverMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<RecoverConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          showAcquisitionLink,
          showRegisterLink,
          showMarketingSiteLink,
          showFreeAtmFinderLink,
          brand,
          programType,
          marketingSiteUrl,
          marketingSiteLinkText,
          openAccountButtonContent,
          signUpContent,
          platformType,
          inAppBrowserAcquisitionToolbarBackgroundColor,
          inAppBrowserAcquisitionDisableToolbarTranslucent,
          signUpDisclaimerContent,
          openAccountFooterContent,
          version,
          mode,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          esignProductNameContent
        } = acpEnv.config;
        return {
          brand,
          programType,
          showAcquisitionLink,
          showRegisterLink,
          showMarketingSiteLink,
          showFreeAtmFinderLink,
          marketingSiteUrl,
          marketingSiteLinkText,
          openAccountButtonContent,
          signUpContent,
          platformType,
          inAppBrowserAcquisitionToolbarBackgroundColor,
          inAppBrowserAcquisitionDisableToolbarTranslucent,
          signUpDisclaimerContent,
          openAccountFooterContent,
          version,
          mode,
          copyrightStatement,
          loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer,
          paybackRewardsDisclaimer,
          cardDisclaimer,
          esignProductNameContent
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.recover
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/recover/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: recoverMfeConfig,
        sessionExpirationDispatcher: sessionExpirationDispatcher,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        sendAnalytics: gtmAdobeLaunchSendAnalytics,
        loginLinks: declarations.login,
        registerLinks: declarations.register,
        logOutLinks: declarations.logout,
        logoLocator,
        iovationPlugin,
        dashboardLinks: declarations.dashboard,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        dynatracePlugin: dynatracePlugin,
        appsFlyerPlugin,
        AcquireNewCustomer,
        clipboardPlugin: clipboardPlugin
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:recover_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): RecoverLinks => ({
          main: root,
          recoverPassword: root.extend`/password`(),
          recoverUsername: root.extend`/username`(),
          recoverHandoff: root.extend`/handoff/${'hashCode'}`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.crypto
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/crypto/implementation')
      ),
      {
        ...commonMfeDependencies,
        dashboardLinks: declarations.dashboard,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        (await permissions({
          'acp:crypto_wallet_active': true
        })) || (await permissions({ 'acp:crypto_wallet_eligible': true })),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/login'))
  );

const locationsMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<LocationsConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { statusBarBackgroundColor } = acpEnv.config;
        return {
          statusBarBackgroundColor
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.locationFinder
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/locations/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: locationsMfeConfig,
        logoLocator,
        loginLinks: declarations.login,
        dashboardLinks: declarations.dashboard,
        moveMoneyLinks: declarations.moveMoney,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:locations_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): LocationsLinks => ({
          main: root,
          distributerLocations: root.extend`/?onlyDistributer=true`(),
          barcodeDetails: root.extend`/barcode-details`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.feeplan.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): FeeplanLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const upgradeAccountMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<UpgradeAccountConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { copyrightStatement } = acpEnv.config;
        return {
          copyrightStatement
        } as UpgradeAccountConfiguration;
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.upgradeAccount
  // If unauthed, go to login
  .implement(
    createInjectable(
      () => ({ loginLinks }) => createPostLoginRedirector(loginLinks),
      { loginLinks: declarations.login }
    ),
    createInjectable(
      () => ({ authenticationApi }) => async () =>
        await authenticationApi({ password: false }),
      { authenticationApi: declarativeAuthenticationApi }
    )
  )
  // If UpgradeOrder and UpgradeActivate ineligible, redirect to dashboard
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/dashboard')),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'webapi:cdda_upgrade_eligible': false,
          'webapi:bdda_upgrade_eligible': false,
          'webapi:product_upgrade_activation_eligible': false
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  // If acp:upgrade_mfe_eligible, mount Upgrade MFE
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/upgrade-account/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: upgradeAccountMfeConfig,
        dashboardLinks: declarations.dashboard,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:upgrade_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  // Else redirect to angular Upgrade
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): UpgradeAccountLinks => ({
          main: root,
          activate: root.extend`/activate`(),
          learnMore: root.extend`/learn-more`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.referAFriend.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): ReferAFriendLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const loyaltyMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<LoyaltyConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { copyrightStatement, mastercardStatement } = acpEnv.config;
        return { copyrightStatement, mastercardStatement };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.loyalty
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/loyalty/implementation')
      ),
      {
        ...commonMfeDependencies,
        Layout,
        mfeConfig: loyaltyMfeConfig,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:loyalty_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): LoyaltyLinks => ({
          main: root,
          enrollment: root.extend`/enrollment`(),
          external: root.extend`/external`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.loyaltyLearnMore.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): LoyaltyLearnMoreLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.externalLoyalty.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): ExternalLoyaltyLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.digitalWallet.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): DigitalWalletLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.connectedBanks.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): ConnectedBanksLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.skylightChecks.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): SkylightChecksLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.premierPlan.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): PremierPlanLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.interstitials.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): InterstitialsLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

const preOnboardingMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<PreOnboardingConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { showAcquisitionLink } = acpEnv.config;
        return {
          showAcquisitionLink
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.preOnboarding
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/login')),
    createInjectable(
      () => ({ isPreOnboardingEligible }) => () => !isPreOnboardingEligible,
      { isPreOnboardingEligible }
    )
  )
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/pre-onboarding/implementation')
      ),
      {
        ...commonMfeDependencies,
        loginLinks: declarations.login,
        activateCardLinks: declarations.activateCard,
        mfeConfig: preOnboardingMfeConfig,
        AcquireNewCustomer,
        appsFlyerPlugin
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:preonboarding_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): PreOnboardingLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.disputeHubTracker
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/dispute-hub-tracker/implementation')
      ),
      {
        ...commonMfeDependencies,
        manageAccountLinks: declarations.manageAccount,
        dashboardLinks: declarations.dashboard,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'webapi:track_lean_dispute_enabled': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DisputeHubLink => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.secureUpload
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/secure-upload/implementation')
      ),
      {
        ...commonMfeDependencies,
        Layout,
        UnauthedLayout,
        iovationPlugin,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        dashboardLinks: declarations.dashboard,
        loginLinks: declarations.login,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:secure_upload_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): SecureUploadLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.rentTrack.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): RentTrackLinks => ({
        main: root
      }),
      'ACP_NG'
    )
  )
);

declarations.handOff
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/handoff/implementation')
      ),
      {
        ...commonMfeDependencies,
        UnauthedLayout,
        iovationPlugin,
        accessTokenDispatcher: webapiAccessTokenDispatcher,
        logOutLinks: declarations.logout,
        secureUploadLinks: declarations.secureUpload,
        fraudDetectionLinks: declarations.fraudDetection,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:handoff_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): HandOffLinks => ({
          main: root,
          handOffActivate: root.extend`/handoff-activate`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.linkExternalAccountSuccess.implement(
  createInjectable(() => () =>
    createMfeToNgInterop(
      (root): LinkExternalAccountSuccessLinks => ({
        main: root,
        withLinkSuccess: root.extend`/link/success`()
      }),
      'ACP_NG'
    )
  )
);

declarations.dormantAccount
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/dormant-account/implementation')
      ),
      {
        ...commonMfeDependencies,
        logoLocator,
        dashboardLinks: declarations.dashboard,
        cardLinks: declarations.card
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'webapi:dormant_self_cure_enabled': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DormantAccountLink => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

declarations.locations
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/locations/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: locationsMfeConfig,
        logoLocator,
        loginLinks: declarations.login,
        dashboardLinks: declarations.dashboard,
        moveMoneyLinks: declarations.moveMoney,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:locations_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): LocationsLinks => ({
          main: root,
          distributerLocations: root.extend`/?onlyDistributer=true`(),
          barcodeDetails: root.extend`/barcode-details`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.offers
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/offers/implementation')
      ),
      {
        ...commonMfeDependencies,
        dashboardLinks: declarations.dashboard,
        Layout,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:cash_rewards_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): OffersLinks => ({
          main: root,
          distributerLocations: root.extend`/?onlyDistributer=true`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.cashRewards
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/cashrewards/implementation')
      ),
      {
        ...commonMfeDependencies,
        dashboardLinks: declarations.dashboard,
        Layout,
        appsFlyerPlugin,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): CashRewardsLink => ({
          main: root,
          distributerLocations: root.extend`/?onlyDistributer=true`(),
          transactionDetails: root.extend`/transaction-details`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.directOffers
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/direct-offers/implementation')
      ),
      {
        ...commonMfeDependencies,
        dashboardLinks: declarations.dashboard,
        Layout,
        appsFlyerPlugin
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): DirectOffersLink => ({
          main: root,
          distributerLocations: root.extend`/?onlyDistributer=true`()
        }),
        'ACP_NG'
      )
    )
  );

declarations.uploadAdditionalDocuments.implement(
  createInjectable(
    createReactMfe(() =>
      import(
        'apps/acp/micro-frontends/upload-additional-documents/implementation'
      )
    ),
    {
      ...commonMfeDependencies,
      UnauthedLayout
    }
  ),
  createInjectable(
    () => ({ permissions }) => async () =>
      await permissions({
        'acp:upload_additional_docs_mfe_eligible': true
      }),
    {
      permissions: declarativePermissionApi
    }
  )
);

const selfEnrollmentMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<SelfEnrollmentConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { patriotActDisclosure, mode } = acpEnv.config;
        return {
          patriotActDisclosure,
          mode
        };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.selfEnrollment
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/self-enrollment/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: selfEnrollmentMfeConfig,
        activateCardLinks: declarations.activateCard,
        anytimeAlertsLinks: declarations.anytimeAlerts,
        contactLinks: declarations.contact,
        loginLinks: declarations.login,
        registerLinks: declarations.register,
        dashboardLinks: declarations.dashboard,
        UnauthedLayout,
        logoutLinks: declarations.logout,
        selfEnrollmentActivateLinks: declarations.activateCard,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:join_today_mobile_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/login'))
  );

declarations.esign
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/esign/implementation')
      ),
      {
        ...commonMfeDependencies,
        manageAccountLinks: declarations.manageAccount,
        Layout,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:esign_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/dashboard'))
  );

declarations.fraudDetection
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/fraud-detection/implementation')
      ),
      {
        ...commonMfeDependencies,
        UnauthedLayout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:handoff_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () =>
      createMfeToNgInterop(
        (root): FraudDetectionLinks => ({
          main: root
        }),
        'ACP_NG'
      )
    )
  );

const secureCreditCardMfeConfig = createInjectable<
  BehaviorSubjectSubscribe<SecureCreditCardConfiguration>,
  {
    bsAcpEnv: BehaviorSubjectSubscribe<AcpEnvironment>;
    ErrorHandling: React.FC;
  }
>(
  () => ({ bsAcpEnv, ErrorHandling }) => {
    return bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const { copyrightStatement, mastercardStatement } = acpEnv.config;
        return { copyrightStatement, mastercardStatement };
      },
      () => ErrorHandling
    );
  },
  { bsAcpEnv, ErrorHandling }
);

declarations.secureCreditCard
  .implement(
    createInjectable(
      createReactMfe(() =>
        import('apps/acp/micro-frontends/secure-credit-card/implementation')
      ),
      {
        ...commonMfeDependencies,
        mfeConfig: secureCreditCardMfeConfig,
        permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
        dashboardLinks: declarations.dashboard,
        Layout
      }
    ),
    createInjectable(
      () => ({ permissions }) => async () =>
        await permissions({
          'acp:secure_credit_card_mfe_eligible': true
        }),
      {
        permissions: declarativePermissionApi
      }
    )
  )
  .implement(
    createInjectable(() => () => createMfeRedirector('/account/dashboard'))
  );
