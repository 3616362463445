import { createElement, ComponentType, FC } from 'react';
import { ComponentCreator } from 'packages/css-component-modules';
import { ButtonBase, ButtonBaseProps } from './button-base';

/**
 * Valid color variant names
 */
export type ColorVariant =
  | 'default'
  | 'accent'
  | 'positive'
  | 'negative'
  | 'special';

/**
 * Prop types for the ButtonVariant component. The generic type is reserved
 * for passing a CCM interface to the variant. It will extend the variant's
 * interface to accept any CCM variables that your variant requires.
 */
export type ButtonVariantProps<T = unknown> = ButtonBaseProps & {
  /**
   * The base component that will be used. If you provide a custom base, it's
   * important to note that it should not have any styles that you need to
   * override. Optimally, it will just be shell with some funcionality and
   * accessibility features.
   *
   * If at all possible, you should leave this as the default value.
   */
  base?: ComponentType;
  /**
   * The variant is the top level button that will be applied to the base
   * component. This is where all the styles should go. Unlike the base, this
   * component should have no real functionality. It should just be a styled
   * button that sits ontop of the already-optimized base.
   */
  variant: ComponentCreator<any>;
  /**
   * Make the button appear enabled, even if it's disabled.
   */
  enabledAppearance?: boolean;

  /**
   * Uses the full width of the parent element.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Determines whether button will be styled built in preset margins
   * @default false
   */
  noMargins?: boolean;

  backgroundTransparent?: string;
  backgroundDisabled?: string;
  textColorDisabled?: string;
  backgroundFocus?: string;
  backgroundDisabledFocus?: string;
  /**
   * Provides border radius to button
   * @default false
   */
  borderRadius?: boolean;
  // background?: string;
} & T;

/**
 * Component that makes your life easier when creating new variants.
 * It automatically extends the `base` component with the specified `variant`.
 * It's also responsible for pulling the current theme and passing the correct
 * colors onto your variant component. These colors can then be accessed from
 * inside your CCM styles.
 */
export const ButtonVariant: FC<ButtonVariantProps> = ({
  base = ButtonBase,
  variant,
  enabledAppearance,
  fullWidth = false,
  noMargins = false,
  borderRadius = false,
  // background,
  ...props
}) => {
  const ButtonComponent = variant.as(base as any);
  return (
    <ButtonComponent
      $disabled={enabledAppearance ? false : props.disabled}
      // $background={background}
      $fullWidth={fullWidth}
      $borderRadius={borderRadius}
      $noMargins={noMargins}
      {...props}
    />
  );
};
