import { createElement } from 'react';
import { DisclosureContainer } from './styles/unauthed-footer-wrapper.ccm.css';
import { UnauthedFooter } from 'apps/acp/packages/acp-unauthed-footer-v2';
import { usePermissions } from '../permissions';
import { useEnvChooserContext } from 'apps/acp/packages/env-chooser';
export const UnauthedFooterWrapper = ({
  copyrightStatement,
  importantPatriotActDisclosure,
  version,
  programType
}) => {
  const {
    showLoginTermsAndConditionsDisclosure,
    showMastercardDisclosures,
    showCardUseDisclosure,
    showShortAppleDisclosure,
    showChangedTermsDepositPrivacyDisclosure
  } = usePermissions();
  const {
    setEnvChooserVisible
  } = useEnvChooserContext();
  return createElement(DisclosureContainer.div, null, createElement(UnauthedFooter, {
    mfeConfig: {
      copyrightStatement,
      importantPatriotActDisclosure,
      version,
      programType,
      showLoginTermsAndConditionsDisclosure,
      showMastercardDisclosures,
      showCardUseDisclosure,
      showShortAppleDisclosure,
      showChangedTermsDepositPrivacyDisclosure,
      mobileAppVersionOnClick: setEnvChooserVisible
    }
  }));
};