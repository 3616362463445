import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-esign.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { ContentBlock } from 'legos/content-block';
import { Body, Bold, LinkBody, Title } from 'legos/typography';
import { EsignLayout, MrgTop, CommunicationLayout, LiLayout } from './styles/acp-biometrics-esign.ccm.css';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
import { useAcpBiometricsTheme } from '../app.theme';
export const BiometricsEsign = ({
  title,
  cancelAnchor,
  children
}) => {
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(Title, null, "E-Sign Consent"), createElement(CommunicationLayout.div, null, createElement(Body, null, "Communications will be sent to the following e-mail address we have on file for you. You can change your email address below."), children)));
};
export const EsignDisclosureContent = ({
  cardType,
  esignProductName,
  brand
}) => {
  const {
    lightDisclosureGreyColor,
    neutralBlackColor
  } = useAcpBiometricsTheme();
  return createElement(EsignLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(MrgTop.div, null, createElement(Body, null, createElement(Content, {
    hash: "096728934e0b6bdcb7ef5aedb69c6531",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Body, null, createElement(Content, {
    hash: "1a9f2bcef43ed503877f74b0f78304c5",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8022caf9c4faad9526fdc1413579c410",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "c99485afee10e3f18de8399ba1503aae",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "a21a90835f134baa5c34ca8ca44075e6",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "bd839b69d7f4b75e82908d232217dda8",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    })],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "399b6b6ccbcb0b8826b5ef26bcd823d4",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "6ab870b9ed9141de266b616d7772c26b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "39f9d5bbb74567b9d792e2bdfc436606",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "aed3ec952840d872de1b59d83003337d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "250b8278fa2c66c8342b912b680c447c",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "2918390f3ad4670280d8b28661b26ad0",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "2160502677443045ad2ed455be9ba913",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "ef5e2e000bac2a58d2376e71360b6d4a",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "14caac091a38aa1717abff080b18e774",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1d53b962f0600686a42f931a01187932",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "c8ef8757508764e4282f4270a416dacc",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "64cab00b86adfed9fad0fcb9beda11c7",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "5f4687e4bf07aec590a955db46edacc6",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "462cec5ddf72ffcafd03003bd1583a75",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "bb95f81a820912b96e167523c989e8b4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "b8f09f585ed344874d35559d38574da1",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(Bold, null, createElement(LinkBody, useExternalLinkHandler(`tel:${brand.phone}`), brand.phone_formatted)), ' ', createElement(Content, {
    hash: "359719b0707e794c1d580a63e4b19c88",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1acdc0288c9b1217a48cb6e1f90aa2b9",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "84d5b57e132fcbf263c17b1b558e0833",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "9456ad7712354d14cb23a32799ee44e2",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })));
};