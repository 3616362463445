
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ButtonBase_cf9a755b465acd4b{position:relative;cursor:pointer;touch-action:manipulation;background-image:none;-moz-appearance:none;-webkit-appearance:none;appearance:none;transition:all .2s ease-in-out;vertical-align:middle;white-space:nowrap;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.ButtonBase_cf9a755b465acd4b:active,.ButtonBase_cf9a755b465acd4b:focus,.ButtonBase_cf9a755b465acd4b:hover,/ .ButtonBase_cf9a755b465acd4b{outline:0;text-decoration:none}.ButtonBase_cf9a755b465acd4b.block_4d14be907d99f758{display:block}.ButtonBase_cf9a755b465acd4b.block_4d14be907d99f758+.ButtonBase_cf9a755b465acd4b{margin-top:-8px}.ButtomBase_da5e839c41399dae.noMargins_3833cfc6be124551{margin:0}.ButtonBasePadding_e83ee81091f47a84{display:block;background:transparent;position:absolute;bottom:-6px;top:-6px;left:0;right:0}`;
styleInject(_css)

/** ButtonBase Props */
export type ButtonBaseCCM = {
  /** ButtonBase Component Custom Properties */
  // No custom properties found

  /** ButtonBase Modifier Flags */
  '$block'?: boolean;
};
/** Base ButtonBase component */
export const ButtonBase: ComponentCreator<ButtonBaseCCM> = createComponentCreator({
  "name": "ButtonBase",
  "base": "ButtonBase_cf9a755b465acd4b",
  "prop": {},
  "mod": {
    "$block": "block_4d14be907d99f758"
  }
});


/** ButtomBase Props */
export type ButtomBaseCCM = {
  /** ButtomBase Component Custom Properties */
  // No custom properties found

  /** ButtomBase Modifier Flags */
  '$noMargins'?: boolean;
};
/** Base ButtomBase component */
export const ButtomBase: ComponentCreator<ButtomBaseCCM> = createComponentCreator({
  "name": "ButtomBase",
  "base": "ButtomBase_da5e839c41399dae",
  "prop": {},
  "mod": {
    "$noMargins": "noMargins_3833cfc6be124551"
  }
});


/** ButtonBasePadding Props */
export type ButtonBasePaddingCCM = {
  /** ButtonBasePadding Component Custom Properties */
  // No custom properties found

  /** ButtonBasePadding Modifier Flags */
  // No modifiers classes found
};
/** Base ButtonBasePadding component */
export const ButtonBasePadding: ComponentCreator<ButtonBasePaddingCCM> = createComponentCreator({
  "name": "ButtonBasePadding",
  "base": "ButtonBasePadding_e83ee81091f47a84",
  "prop": {},
  "mod": {}
});

