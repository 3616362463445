import __NS_DYNAMIC_REACT_CONTENT___0 from "./paypal-unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure as BaseDisclosure } from 'legos/typography';
import { IssuerStatement } from 'apps/acp/packages/issuer-statement/react';
import { CopyrightStatement } from 'apps/acp/packages/content-copyright-statement';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
import { DisclosureStyle } from './styles/paypal-unauthed-footer.ccm.css';
const isCordova = isAndroid() || isIOS();
const Disclosure = DisclosureStyle.as(BaseDisclosure);
export const PayPalUnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "801e82c782762ee6517b73468cfa1bd0",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "30d7188d0dd1c1f59d4702dd08205a22",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.importantPatriotActDisclosure && createElement(Disclosure, {
    dangerouslySetInnerHTML: {
      __html: mfeConfig.importantPatriotActDisclosure
    }
  }), createElement(Disclosure, null, createElement(IssuerStatement, null)), mfeConfig.showMastercardDisclosures && createElement(Disclosure, null, createElement(Content, {
    hash: "4a75f5dcd2ac43a74ba619607f0ff295",
    devVariables: {},
    componentsAndExpressions: [() => !mfeConfig.showCardUseDisclosure && createElement(Fragment, {
      key: "showCardUseDisclosure"
    }, createElement("br", null), createElement("br", null), createElement(Content, {
      hash: "85475809dbaaacad999199e8b2524213",
      devVariables: {},
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.showShortAppleDisclosure && createElement(Disclosure, null, createElement(Content, {
    hash: "a8f43d54a784b48c3f97050de4765ae1",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "7edd9a08773e34daaeb391d3b97bab39",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "74db0a08f232d5b940c23f4aff533a23",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "71e23c239787663d87d72180012dead4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, createElement(CopyrightStatement, {
    statement: mfeConfig.copyrightStatement
  })), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "502ffda48c955d3cb274d6203ed6b412",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};
export default PayPalUnauthedFooter;