export default { 
  h30d7188d0dd1c1f59d4702dd08205a22: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  h4a75f5dcd2ac43a74ba619607f0ff295: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h502ffda48c955d3cb274d6203ed6b412: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  h71e23c239787663d87d72180012dead4: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h74db0a08f232d5b940c23f4aff533a23: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h7edd9a08773e34daaeb391d3b97bab39: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  h801e82c782762ee6517b73468cfa1bd0: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  h85475809dbaaacad999199e8b2524213: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]],
  ha8f43d54a784b48c3f97050de4765ae1: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]] 
}